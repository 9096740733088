import React from 'react'
import CheatingPreemploymentTest from '../components/recruitment_blogs/blogs/cheating-preemployment-test/cheating-preemployment-test'
import SEO from '../components/seo'

const Page = (location) => {
  return (<React.Fragment>
      <SEO title="How to Avoid Cheating in Virtual Pre Employment Tests in 2021"
        description="How to avoid cheating in virtual pre-employment tests? Proctored vs unproctored pre-employment tests. How to stop cheating in online pre-employment tests? Read more."
        url="/cheating-preemployment-test/"
        image="https://blogs.mycareerdreams.com/blogs/avoid_cheating_img.webp"
        type="article"
      />
    <CheatingPreemploymentTest location={location}/>
  </React.Fragment>
  )
}
export default Page;