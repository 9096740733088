import React from 'react'
import BlogHeader from '../../blog_header/blog_header'
import * as classess from '../blogs_styling.module.css'

import avoid_cheating_img from './static/avoid_cheating_img.png'
import avoid_cheating_img_webp from './static/avoid_cheating_img.webp'

import pre_employment_img from './static/pre_employment_img.png'
import pre_employment_img_webp from './static/pre_employment_img.webp'

import multiple_tabs_img from './static/multiple_tabs_img.png'
import multiple_tabs_img_webp from './static/multiple_tabs_img.webp'


import external_resources_img from './static/external_resources_img.png'
import external_resources_img_webp from './static/external_resources_img.webp'

import technological_manipulation_img from './static/technological_manipulation_img.png'
import technological_manipulation_img_webp from './static/technological_manipulation_img.webp'

import prevent_cheating_img from './static/prevent_cheating_img.png'
import prevent_cheating_img_webp from './static/prevent_cheating_img.webp'

import utility_factor_img from './static/utility_factor_img.png'
import utility_factor_img_webp from './static/utility_factor_img.webp'

import webcams_img from './static/webcams_img.png'
import webcams_img_webp from './static/webcams_img.webp'

import Footer from '../../../footer/footer'
import Social from '../../../social/social'
import Scroll from '../scroll/scroll'


const CheatingPreemploymentTest = (props) => {
    return <React.Fragment>
        <Scroll showBelow={250} />
        <BlogHeader title="How to Avoid Cheating in Virtual Pre Employment Tests in 2021" />
        <div className={classess.blog_content_main}>
            <h1 className={classess.heading_one}>
                How to Avoid Cheating in Virtual Pre Employment Tests in 2021
            </h1>
            <picture>
                <source data-srcSet={avoid_cheating_img_webp} type="image/webp" />
                <source data-srcSet={avoid_cheating_img} type="image/png" />
                <img data-srcSet={avoid_cheating_img_webp} alt="Main Image" loading="lazy" className={classess.main_image_styling} />
            </picture>
            <div className={classess.content_styling}>
                Pre-Employment assessments are becoming more and more popular in the hiring scheme, especially in the new work from the home format, which makes the online platform of these assessments more accessible. Online assessments can range Afrom cognitive, psychosomatic, or even soft skills testing and are becoming widely used because of efficient models, making them easy to use, less time-consuming, cost-efficient and objective. <br /><br />
            However, even with a method that is so technologically advance and efficient, there are setbacks. One of the biggest ones being cheating. Pre-employment testing can be either be proctored or unproctered, where proctored testing would require someone to monitor testing through a webcam and an invigilator to monitor the assessment.  Of course, these would require both time and a good deal of money, making unproctered internet testing (UIT)  the most popular method of testing.

            </div>
            <h2 className={classess.heading_two}>
                Pre-Employment Testing and Cheating: The Details
            </h2>
            <picture>
                <source data-srcSet={pre_employment_img_webp} type="image/webp" />
                <source data-srcSet={pre_employment_img} type="image/png" />
                <img data-srcSet={pre_employment_img_webp} alt="Main Image" loading="lazy" className={classess.main_image_styling} />
            </picture>
            <div className={classess.content_styling}>
                When UITs started gaining more and more popularity, many psychologists and employers began looking into the problems it posed, the biggest being cheating. In 1973 American Sociologist, Donald Cressey came up with what became known as the fraud triangle which consisted of three main elements: pressure to cheat, opportunity to cheat, and rationalization; the absence of proctoring and the high stakes of a situation represent such factors that could be conducive to cheating. Any situation involving UIT and the existence of any of the factors in the fraud triangle will make the situation conducive to cheating. In fact, a study conducted to estimate the value of cheating found that nearly 40% of test-takers were involved in e-cheating.
            </div>
            <h2 className={classess.heading_two}>
                How do People Cheat in Online Tests?
            </h2>
            <div className={classess.content_styling}>
                Given the unprotected nature of UIT and the online platform, people have come up with a number of ways around it, some more creative than others.
            </div>
            <center>
                {/* <picture>
                    <source data-srcSet={second_img} type="image/png" />
                    <source data-srcSet={second_img_webp} type="imgae/webp" />
                    <img data-srcSet={second_img_webp} alt="Sub" className={classess.main_image_styling} />
                </picture> */}
            </center>
            <ul className={classess.list_styling}>
                <li>
                    <b> 1. Getting someone else to take the test:</b>  In many cases, the candidate applying for the job simply does not take the test themself. This is especially true in the case of more technical exams, where the candidate can simply ask a friend/ family or even outsource it to someone more qualified. In certain cases, candidates even take tests in groups, allowing them to consult more than one person during the assessment.
                </li>
                <li>
                    <b>2. Using Multiple tabs/ Smartphones:</b> <br /><br />
                    <picture>
                        <source data-srcSet={multiple_tabs_img_webp} type="image/webp" />
                        <source data-srcSet={multiple_tabs_img} type="image/png" />
                        <img data-srcSet={multiple_tabs_img_webp} alt="Main Image" loading="lazy" className={classess.main_image_styling} />
                    </picture> <br /><br />
                    Candidates could also have multiple tabs open on their computers, where they google or look up answers or even have saved information, to help them gain the information they need to cheat the assesment. Moreover, they could also use the screenshare option to cheat by sharing their screen with someone helping them or to simply record and reattempt.  Sometimes candidates even keep their smartphones around and use that for answers.
                </li>
                <li>
                    <b>3. Prior knowledge of the test material:</b>  In most competitive pre-employment tests, especially those with a large pool of possible candidates, most people go out of their way to make sure they know the assessment will be tested before they attempt the test. This can be accomplished when people share the questions and answers with their friends who are potential candidates or even when the material is uploaded on an online sharing platform, putting the validity of the test in question.
                </li>
                <li>
                    <b>4. External Resources:</b> <br /><br />
                    <picture>
                        <source data-srcSet={external_resources_img_webp} type="image/webp" />
                        <source data-srcSet={external_resources_img} type="image/png" />
                        <img data-srcSet={external_resources_img_webp} alt="Main Image" loading="lazy" className={classess.main_image_styling} />
                    </picture> <br /><br />
                    The existence of an online platform of assessment allows for more room for the use of external resources. This could involve the use of resources like calculators, dictionaries, external notes.
                </li>
                <li>
                    <b>5. Technological Manipulation:</b> <br /><br />
                    <picture>
                        <source data-srcSet={technological_manipulation_img_webp} type="image/webp" />
                        <source data-srcSet={technological_manipulation_img} type="image/png" />
                        <img data-srcSet={technological_manipulation_img_webp} alt="Main Image" loading="lazy" className={classess.main_image_styling} />
                    </picture> <br /><br />
                     Fully remote assessments also face problems when candidates figure out how to tamper with timers, by changing their computers timing for example, or requesting new logins to retake the same test, etc.
                </li>
                <li>
                    <b> 6. Resetting tests: </b>  Pre-employment assessment cheating techniques can vary in accordance to the platform they are conducted on, however, technological manipulation seems to be a very commonly used method all across. This could involve turning the internet off on purpose, therefore resetting the test, allowing the candidate to correct their mistake.
                </li>
                <li>
                    <b>7. Taking the test more than once:</b>  Many times when assessments are complicated in nature, or even if the candidate doesn’t perform well, they find a way to retake the test and attempt it again. This can be done by either disconnecting in the middle of the test or logging in through a different id and username to reattempt it. Either way, through their prior knowledge of the test, they put the results of the assessment under question.
                </li>
            </ul>


            <h2 className={classess.heading_two}>
                How to Prevent Online Cheating
            </h2>

            <ul className={classess.list_styling}>
                <li>
                    <b> 1. Positive communication: </b> <br /><br />
                    <picture>
                        <source data-srcSet={prevent_cheating_img_webp} type="image/webp" />
                        <source data-srcSet={prevent_cheating_img} type="image/png" />
                        <img data-srcSet={prevent_cheating_img_webp} alt="Main Image" loading="lazy" className={classess.main_image_styling} />
                    </picture> <br /><br />
                    The point of using AIs and online tests is to make sure the hiring process is to eliminate bias and make the process as efficient as possible. Its meant to benefit both the employer and the employee, yet if this is not communicated to potential candidates properly, they feel encouraged to cheat. They have no accountability, and they feel like it’s easier cheating a system than it is to cheat a human. So the best way around this is to communicate a clear set of instructions and explanations. Explain the structure, tell them about the purpose each question serves. And the most important of all, communicate with them clearly what kind of candidate you want, and what you expect from them so that they feel less inclined to lie and cheat for a job they are not fit for.
                </li>
                <li>
                    <b> 2. Add the Utility factor: </b>
                    <br /><br />
                    <picture>
                        <source data-srcSet={utility_factor_img_webp} type="image/webp" />
                        <source data-srcSet={utility_factor_img} type="image/png" />
                        <img data-srcSet={utility_factor_img_webp} alt="Main Image" loading="lazy" className={classess.main_image_styling} />
                    </picture> <br /><br />
                    A great and very underrated way of reducing possible cheating within online assessments is through communication and assessment. With fully automated systems, the human experience is often lost. And when candidates are only exposed to an automated system, they are more inclined to cheat because the guilt factor is no longer present when they are only performing tasks and doing tests. Hence a good way to make sure clients don’t cheat is to add the human element. Make them know that they are not simply cogs in a giant hiring machine, who will be used and discarded immediately if they do not fit certain requirements. And most importantly, remind them this system is there to ensure the eradication of bias, to save time and money for both sides, so adhering to the rules will work for everyone’s favour.
                </li>
                <li>

                    <b>3. Use of Webcams:</b> <br /><br />

                    <picture>
                        <source data-srcSet={webcams_img_webp} type="image/webp" />
                        <source data-srcSet={webcams_img} type="image/png" />
                        <img data-srcSet={webcams_img_webp} alt="Main Image" loading="lazy" className={classess.main_image_styling} />
                    </picture> <br /><br />
                    Perhaps the simplest solution is to employ the use of a webcam. The use of a webcam would make sure the test is monitored, and by putting forward certain limitations or instructions, the entire process can be made even more efficient. Candidates can be asked to only take the test where their background is visible and where there are no people or background test. They can also be asked to keep their hands visible and the desk/ test-taking area clear the whole time they are attempting the test.
                </li>
                <li>
                    <b> 4. Tab Monitoring:</b>  One of the most used ways of cheating amongst candidates happens to the use of multiple tabs to refer to the necessary information. Hence employers can make sure a candidate doesn’t have multiple tabs open for external help is through software that monitors how many times the candidate shifts from one tab to the other, allowing the employer to monitor the candidate’s behaviour during the test, and therefore ensure accurate results.
                </li>
                <li>
                    <b>5. Question randomization:</b>  Keeping questions in a set order isn’t a good idea, especially when it comes to online assessments. As mentioned above, candidates share answers, codes, connect and disconnect, reset and regive entire tests, and having a particular order just makes the whole ordeal easier. Hence, keeping a randomized order is usually a better option. That way, candidates won’t just blindly re-enter all the answers they already know. Moreover, the switch can also confuse candidates who begin the test with prior knowledge or cheat codes, since they can no longer blindly apply what they already know.
                </li>
                <li>
                    <b>6. Technological Solutions:</b>  Just because the online platform provides newer, easier means of cheating, doesn’t mean it cannot provide newer, easier means of combatting it too. This can be very simple like disabling the copy-paste option or can be a little more advance like using anti-plagiarism software, which ensures the answers provided are authentic to the candidate.
                </li>
            </ul>

            <h3 className={classess.heading_two}>Conclusion:</h3>
            <div className={classess.content_styling}>
                Dealing with online cheating in pre-employment tests can be very daunting since it seems like candidates keep coming up with one way after the other. However, it is important to keep in mind, that times are changing, and one must keep up. Pre-employment assessments are not only the newest means of hiring, but the most efficient and foolproof and the same technology candidates use to manipulate employers, can be used against them through some very simple, small steps.
            </div>

        </div>
        <Social url={`https://blogs.mycareerdreams.com${props.location.path}`} />
        <Footer />
    </React.Fragment>
}
export default CheatingPreemploymentTest;